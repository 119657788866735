var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"commonDialog":_vm.commonDialog,"dialogWidth":_vm.dialogWidth},on:{"update:commonDialog":function($event){_vm.commonDialog=$event},"update:common-dialog":function($event){_vm.commonDialog=$event},"update:dialogWidth":function($event){_vm.dialogWidth=$event},"update:dialog-width":function($event){_vm.dialogWidth=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-layout',[_c('v-flex',[_vm._v(" Manage Lead Statuses ")]),_c('v-flex',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mx-4",attrs:{"small":""},on:{"click":function($event){return _vm.createStatus()}}},[_c('span',{staticClass:"font-size-16 font-weight-600"},[_vm._v("Add Status")])])],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('table',{staticClass:"width-100"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"px-4 font-size-16 font-weight-600",attrs:{"align":"center","width":"10%"}},[_vm._v(" Color ")]),_c('td',{staticClass:"px-4 font-size-16 font-weight-600",attrs:{"align":"center","width":"15%"}},[_vm._v(" Text Color ")]),_c('td',{staticClass:"px-4 font-size-16 font-weight-600"},[_vm._v("Text")]),_c('td',{staticClass:"px-4 font-size-16 font-weight-600",attrs:{"width":"10%"}})]),_vm._l((_vm.statusesList),function(type,index){return _c('tr',{key:index},[_c('td',{staticClass:"px-4",attrs:{"align":"center"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"background-color":"#f5f5f5"},attrs:{"color":type.color,"text":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-record")])],1)]}}],null,true)},[_c('span',[_vm._v("Status Color")])])]}}],null,true)},[_c('div',[_c('v-color-picker',{staticClass:"ma-2",attrs:{"show-swatches":"","hide-canvas":"","hide-inputs":"","hide-mode-switch":"","hide-sliders":"","swatches-max-height":"300px"},on:{"update:color":function($event){return _vm.update_color($event, 'color', index)}},model:{value:(type.color),callback:function ($$v) {_vm.$set(type, "color", $$v)},expression:"type.color"}})],1)])],1),_c('td',{staticClass:"px-4",attrs:{"align":"center"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"background-color":"#f5f5f5"},attrs:{"color":type.textcolor,"text":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-record")])],1)]}}],null,true)},[_c('span',[_vm._v("Status Text Color")])])]}}],null,true)},[_c('div',[_c('v-color-picker',{staticClass:"ma-2",attrs:{"show-swatches":"","hide-canvas":"","hide-inputs":"","hide-mode-switch":"","hide-sliders":"","swatches-max-height":"300px"},on:{"update:color":function($event){return _vm.update_color($event, 'textcolor', index)}},model:{value:(type.textcolor),callback:function ($$v) {_vm.$set(type, "textcolor", $$v)},expression:"type.textcolor"}})],1)])],1),_c('td',[_c('v-text-field',{ref:"status",refInFor:true,staticClass:"px-4 py-2",attrs:{"dense":"","filled":"","label":"Status","solo":"","flat":"","hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan"},model:{value:(type.text),callback:function ($$v) {_vm.$set(type, "text", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"type.text"}})],1),_c('td',{attrs:{"width":"50"}},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"deep-orange"},on:{"click":function($event){return _vm.deleteStatus(index)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Click here to delete")])])],1)])})],2)])])],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button white--text",attrs:{"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan"},on:{"click":function($event){return _vm.updateStatusSetting()}}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.pageLoading},on:{"click":function($event){return _vm.$emit('close-dialog', true)}}},[_vm._v(" Close ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }